import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "style-guide"
    }}>{`Style Guide`}</h1>
    <iframe title='figma' style={{
      borderWidth: '4px',
      borderStyle: 'solid',
      borderColor: '#eee',
      width: '800px',
      height: '450px'
    }} src='https://www.figma.com/embed?embed_host=share&url=https://www.figma.com/file/QaYbQ2LGdA3lefOWZCosDcrt/DSC-NYC-logos' allowFullScreen />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      